import { Typography, Icon, LanguageIcon } from '@norges-domstoler/dds-components';
import { getLocalizationText } from '../../../../../utils/getLocalizationText';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { MenuItem } from '../../MenuItem/TilsynsutvalgetMenuItem';
import { useEffect } from 'react';

export type TopMenuItemsProps = {
    settings: LayoutSettings;
    isOpenMenu: boolean;
    menuRef: React.MutableRefObject<HTMLDivElement>;
    setIsOpenMenu: (isMenuOpen: boolean) => void;
    setIsOpenLanguageSelector: (value: React.SetStateAction<boolean>) => void;
};

export const TopMenuItems = ({
    settings,
    isOpenMenu,
    menuRef,
    setIsOpenMenu,
    setIsOpenLanguageSelector,
}: TopMenuItemsProps) => {
    const useLanguageSelector = settings?.useLanguageSelector?.value;
    const currentLanguage = settings?.language?.name;

    useEffect(() => {
        const handleTabKey = (event: KeyboardEvent) => {
            if (event.key === 'Tab' && isOpenMenu) {
                const menuLinks = menuRef.current?.querySelectorAll('a');
                const firstMenuLink = menuLinks?.[0];
                const lastMenuLink = menuLinks?.[menuLinks.length - 1];
                if (document.activeElement === firstMenuLink && event.shiftKey) {
                    setIsOpenMenu(false);
                } else if (document.activeElement === lastMenuLink && !event.shiftKey) {
                    setIsOpenMenu(false);
                }
            }
        };

        const header = document.getElementById('tu-header');
        const targetElement = header || document;
        targetElement.addEventListener('keydown', handleTabKey);

        return () => {
            targetElement.removeEventListener('keydown', handleTabKey);
        };
    }, [isOpenMenu, menuRef, setIsOpenMenu]);

    return (
        <div className="menu-container" id="menu" role="menu">
            {useLanguageSelector && (
                <div className="menu-icon-button language-selector-button">
                    <button
                        onClick={() => {
                            setIsOpenLanguageSelector((prevState: boolean) => !prevState);
                            setIsOpenMenu(false);
                        }}
                    >
                        <Icon icon={LanguageIcon} iconSize="medium" />
                        <Typography typographyType="bodySans03">
                            {getLocalizationText(currentLanguage, ['Header', 'Language'])}
                        </Typography>
                    </button>
                </div>
            )}
            <MenuItem links={settings?.beforeMeetingTheCourtsLinks} handleOnClick={() => setIsOpenMenu(false)} />
            <MenuItem links={settings?.summonedForTrialLinks} handleOnClick={() => setIsOpenMenu(false)} />
            <MenuItem links={settings?.publicAndInsightsLinks} handleOnClick={() => setIsOpenMenu(false)} />
            <MenuItem links={settings?.themeLinks} handleOnClick={() => setIsOpenMenu(false)} />
            <MenuItem links={settings?.servicesLinks} handleOnClick={() => setIsOpenMenu(false)} />
        </div>
    );
};

export default TopMenuItems;
